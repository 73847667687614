import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({
  description, lang, meta, title, image: metaImage,
}) {
  const { site, coverImage } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
        coverImage: file(relativePath: { eq: "socialCover.png" }) {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
          }
        }
      }
    }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  const image = coverImage && coverImage.childImageSharp.resize.src
    ? `${site.siteMetadata.siteUrl}${coverImage.childImageSharp.resize.src}`
    : null;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:url',
          content: site.siteMetadata.siteUrl,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata?.author || '',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'robots',
          content: 'max-image-preview:large',
        }
      ].concat(
        image
          ? [
            {
              property: 'og:image',
              content: image,
            },
            {
              property: 'og:image:width',
              content: coverImage.childImageSharp.resize.width,
            },
            {
              property: 'og:image:height',
              content: coverImage.childImageSharp.resize.height,
            },
            {
              name: 'twitter:card',
              content: 'summary_large_image',
            },
          ]
          : [
            {
              name: 'twitter:card',
              content: 'summary',
            },
          ],
      ).concat(meta)}
    >
      <script type="application/ld+json">
        {`{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "Хочу Суши",
  "alternateName": "Хочу Суши — Доставка вкусной еды",
  "url": "https://wantsushi.ru/",
  "logo": "https://sushi-image.storage.yandexcloud.net/logo_aad65bb449.png",
  "sameAs": [
    "https://wantsushi.ru/",
    "https://vk.com/ho4u_susi",
    "https://instagram.com/hochusushisevastopol"
  ]
}`}
      </script>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
